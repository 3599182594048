import React from 'react'
import { Container, Layout, Heading, Text, Link } from '../components'

const NotFoundPage: React.FC<unknown> = () => {
  return (
    <Layout>
      <Container>
        <Heading level="1" mb="small">
          Page not found
        </Heading>
        <Text mb="xlarge">
          Oops, the page you are looking for has been removed or relocated
        </Text>
        <Link to="/">Go Home</Link>
      </Container>
    </Layout>
  )
}

export default NotFoundPage
